export default class CampaignOption {
  constructor(payload) {
    this.id = payload.id;
    this.name = payload.name;
    this.startDate = payload.startDate;
    this.endDate = payload.endDate;
    this.remainingDays = payload.remainingDays;
    this.posmCount = payload.posmCount;
    this.dataCount = payload.dataCount;
  }
}
