<template>
  <div :class="`transition mb-4 ${imgUrl ? 'height-increase' : ''}`">
    <b-form-group
      class="mb-2"
      v-if="posmCategoryData.type === inputType.IMAGE"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-file
        :id="
          `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        accept="image/jpeg, image/png, image/gif"
        @input="fileChangeHandler"
        required
      ></b-form-file>
      <b-form-text>Hình cần có định dạng: jpg, png, gif.</b-form-text>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === inputType.TEXT"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-input
        :id="
          `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        required
      ></b-form-input>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === inputType.FILE"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="
        `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
      "
    >
      <b-form-file
        :id="
          `${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
        "
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        required
      ></b-form-file>
      <b-form-text>File cần có dung lượng không lớn hơn 1MB.</b-form-text>
    </b-form-group>
    <b-form-group
      class="mb-2"
      v-else-if="posmCategoryData.type === inputType.DROPDOWN"
      :label="`Vị trí hạng mục: ${posmCategoryData.position}`"
      :label-for="`${side}-form-categories-${posmCategoryDataIndex}`"
    >
      <b-form-select
        :id="`${side}-form-categories-${posmCategoryDataIndex}`"
        v-model="posmCategoryData.data"
        :state="Boolean(posmCategoryData.data)"
        required
      ></b-form-select>
    </b-form-group>
    <div :class="`text-center ${isUploading ? 'img-blur' : ''}`">
      <b-img-lazy
        :src="imgUrl ?? posmCategoryData.previewData"
        alt=""
        v-if="
          (imgUrl || posmCategoryData.previewData) &&
            posmCategoryData.type === inputType.IMAGE
        "
        class="img-fluid mb-4"
      />
    </div>
    <div :class="`${isUploading ? 'img-blur' : ''}`">
      <b-form-group
        v-if="
          posmCategoryData.individuals &&
            posmCategoryData.individuals.length > 0
        "
      >
        <p class="font-weight-bold mb-1">Nhóm tham gia điền:</p>
        <b-row>
          <b-col
            lg="4"
            sm="6"
            cols="6"
            v-for="(individual, index) of posmCategoryData.individuals"
            class="mb-2"
            v-bind:key="
              `${index}-${individual}-${side}-${posmCategory.id}-${posmCategory.name}-${posmCategoryData.position}-form-categories-${posmCategoryDataIndex}`
            "
          >
            <span>{{ individual }}</span>
          </b-col>
        </b-row>
      </b-form-group>
    </div>
  </div>
</template>
<script>
import { cmdUrl } from '@/utils/apiUrl';
import ApiService from '@/core/services/api.service';
import { SUCCESS_RESPONSE_STATUS } from '@/utils/constants';
import { makeToastFaile, makeToastSuccess } from '@/utils/common';
import { POSM_INPUT_TYPE } from '@/utils/enum';
import { swalMixin } from '@/view/mixins';
import { EventBus } from '@/core/services/event-bus';

function setFormData(data, file) {
  data.append(
    'posmSubmissionItemUuid',
    this.posmCategoryData.posmSubmissionItemUuid,
  );
  data.append('posmId', this.posmCategoryData.id);
  data.append('posmSubmissionUuid', this.posmSubmissionUuid);
  data.append('employeeId', this.employeeId);
  data.append('file', file);
}

export default {
  name: 'POSMFileInput',
  mixins: [swalMixin],
  data() {
    return {
      imgUrl: null,
      confirmation: false,
      verifyResult: {},
      isUploading: false,
      previousImage: null,
    };
  },
  props: {
    inputType: {},
    posmCategoryData: {},
    posmCategoryDataIndex: {},
    employeeId: {},
    side: {},
    posmSubmissionUuid: {},
    selectedCampaign: {},
    posmCategory: {
      type: Object,
      default() {
        return {
          id: 0,
          inputType: POSM_INPUT_TYPE.IMAGE,
          name: '',
          data: [],
        };
      },
    },
  },
  methods: {
    fileChangeHandler(file) {
      this.checkAlreadyFilledPosm(file);
    },
    postImage(file) {
      this.$set(this.posmCategoryData, 'data', file);
      if (file && file instanceof File) {
        this.isLoading = true;
        this.imgUrl = URL.createObjectURL(file);
        const data = new FormData();
        setFormData.call(this, data, file);
        ApiService.post(
          `${cmdUrl.PosmFormSubmission.root}/${this.posmSubmissionUuid}`,
          data,
        )
          .then(({ data }) => {
            if (data.status === SUCCESS_RESPONSE_STATUS) {
              this.$emit('post:posm-image', true);
              if (
                this.selectedCampaign.dataCount >=
                this.selectedCampaign.posmCount
              ) {
                this.postMarkAsSubmitted();
              }
            }
          })
          .catch((err) => {
            this.$emit('post:posm-image', false);
            makeToastFaile(
              err.response.data
                ? err.response.data.message
                : 'Có lỗi xảy ra. Hãy thử lại',
            );
          })
          .finally(() => {
            this.isUploading = false;
          });
        return;
      }
    },

    postMarkAsSubmitted() {
      ApiService.post(
        `${cmdUrl.PosmFormSubmission.root}/mark-as-submitted/${this.posmSubmissionUuid}`,
      )
        .then(({ data }) => {
          if (
            data.status === SUCCESS_RESPONSE_STATUS &&
            this.selectedCampaign.dataCount === this.selectedCampaign.posmCount
          ) {
            if (!this.selectedCampaign.isSubmitted) {
              this.selectedCampaign.isSubmitted = true;
              makeToastSuccess(
                'Bạn đã gửi báo cáo cho Campaign này thành công',
              );
              return;
            }

            makeToastSuccess(
              'Bạn đã cập nhật báo cáo cho Campaign này thành công',
            );
          }
        })
        .catch((err) => {
          makeToastFaile(err.response ? err.response.data.message : 'Lỗi');
        });
    },
    checkAlreadyFilledPosm(file) {
      this.isUploading = true;

      ApiService.post(`${cmdUrl.PosmFormSubmission.root}/check/filled-item`, {
        posmSubmissionUuid: this.posmSubmissionUuid,
        posmSubmissionItemUuid: this.posmCategoryData.posmSubmissionItemUuid,
      })
        .then(({ data }) => {
          this.$emit('posm-refresh');
          this.verifyResult = data.data;
          this.showPopup(file);
        })
        .catch((error) => {
          this.$emit('posm-refresh');
          this.popupSwalError({ title: 'Lỗi!', text: error.message });
          this.revertToPreviousImage();
        });
    },
    revertToPreviousImage() {
      EventBus.$emit('revert-to-previous-image');
    },
    showPopup(file) {
      if (this.verifyResult.enablePopup && file && file instanceof File) {
        this.popupSwalWarning({
          title: 'Bạn có chắc muốn thay đổi ảnh?',
          text:
            'Vị trí hạng mục này ĐÃ ĐƯỢC ĐIỀN bởi một cá nhân khác. Nếu nhấn đồng ý, ảnh hiện tại sẽ bị thay thế bằng ảnh mới.',
        }).then((data) => {
          if (data.isConfirmed) {
            this.postImage(file);
          }
          if (!data.isConfirmed) {
            this.revertToPreviousImage();
          }
        });
      } else if (this.verifyResult.enablePopup && !file) {
        this.revertToPreviousImage();
      }
      if (!this.verifyResult.enablePopup) {
        this.postImage(file);
      }
    },
  },
  watch: {
    'posmCategoryData.data': {
      handler() {
        if (
          this.posmCategoryData.data &&
          this.posmCategoryData.data instanceof File
        ) {
          this.imgUrl = URL.createObjectURL(this.posmCategoryData.data);
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    if (
      this.posmCategoryData.data &&
      this.posmCategoryData.data instanceof File
    ) {
      this.previousImage = new File(
        [this.posmCategoryData.data],
        this.posmCategoryData.data.name,
      );
      this.imgUrl = URL.createObjectURL(this.posmCategoryData.data);
    }
  },
};
</script>
<style scoped>
.transition {
  transition: height 0.3s;
}

.height-increase {
  height: fit-content;
}

.img-blur {
  filter: blur(4px);
  height: 100%;
  transition: filter 0.3s;
}
</style>
