export default class POSMCategoryData {
  constructor(position, data, type, startDate, endDate, note, id) {
    this.position = position;
    this.data = data;
    this.type = type;
    this.startDate = startDate;
    this.endDate = endDate;
    this.note = note;
    this.id = id;
  }
}
