<template>
  <b-list-group>
    <b-list-group-item
      v-for="index of quantity"
      v-bind:key="`campaign-loading-${index}`"
    >
      <b-skeleton width="100%"></b-skeleton>
    </b-list-group-item>
  </b-list-group>
</template>
<script>
export default {
  name: 'CampaignSkeleton',
  props: {
    campaignOptions: {},
    quantity: {},
  },
};
</script>
